<template>
  <v-list class="leftnav">
    <template v-for="(item, i) in items">
      <v-subheader v-if="item.type === 'title'" :key="i" :dark="isDark">{{ $t(item.tKey) }}</v-subheader>

      <v-divider v-else-if="item.type === 'divider'" :key="i" class="my-4" />

      <v-list-item v-else :key="i" link v-on:click="$router.push(item.targetUrl)" v-bind:class="classObject(item)" :dark="isDark">
        <v-list-item-action class="mr-4">
          <v-icon :color="item.color || textColor">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title > {{ $t(item.tKey) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { isDark } from "@/services/theme";

export default {
  props: {
    items: Array,
  },
  data: () => ({}),
  computed: {
    isDark: function() {
      return isDark();
    },
    textColor: function() {
      return isDark()? "white": "black";
    }
  },
  methods: {
    classObject: function(item) {
      return ["level" + (item.level || "1"), this.$route.path == item.targetUrl ? "v-list-item--active" : ""];
    },
  },
};
</script>

<style lang="scss">
.leftnav {
  padding: 0;

  .level2 {
    padding-left: 30px;
  }
}
</style>
