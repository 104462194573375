<template>
  <v-footer dark padless inset class="d-print-none">
    <v-expansion-panels multiple>
      <v-expansion-panel v-if="hasSlotData">
        <v-expansion-panel-header>Debug</v-expansion-panel-header>
        <v-expansion-panel-content>
          <slot />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Debug</v-expansion-panel-header>
        <v-expansion-panel-content>
          Profile: <pre>{{ profile }}</pre>
          Settings: <pre>{{ settings }}</pre>
          User: <pre>{{ user }}</pre>
          Config: <pre>{{ config }}</pre>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapGetters(["profile", "settings", "user", "config"]),
    hasSlotData: function() {
      return this.$slots.default;
    },
  },
};
</script>
