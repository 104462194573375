<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout" top right rounded="pill" class="pa-0 ma-0">
      {{ message }}
      <v-btn text @click.native="show = false">{{ $t("common.labels.close") }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      timeout: 3000,
      color: "orange",
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showMessage") {
        const s = state.SnackBar.snack;
        if (s && s.message) {
          this.message = s.message;
          this.timeout = s.timeout || 5000;
          this.color = s.color || "cyan darken-2";
          this.show = true;
        }
      }
    });
  },
};
</script>