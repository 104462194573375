<template>
  <v-navigation-drawer v-model="drawer" app clipped color="navbg" :style="style">
    <NavigationBLock :items="visibleNav" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { getColor, isDark } from "@/services/theme";
import NavigationBLock from "./navigationblock";

const navData = require("../../router/navdata.json");

export default {
  data: function() {
    return {
      drawer: null,
      navItems: navData,
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin", "role"]),
    visibleNav: function() {
      return this.navItems.filter((n) => n.roles.includes(this.role || "pub"));
    },
    isDark: function() {
      return isDark();
    },
    style: function() {
      return `background: ${getColor("navbg")}; background: linear-gradient(130deg, ${getColor("navbgtop")} 15%, ${getColor("navbg")} 66%;`;
    },
  },

  methods: {
    show: function() {
      this.drawer = true;
    },
  },

  components: {
    NavigationBLock,
  },
};
</script>

<style></style>
