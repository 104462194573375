<template>
  <v-app-bar app clipped-left :style="headerStyle" elevation="1" :prominent="makeProminent">
    <v-app-bar-nav-icon @click="headerIconClick()" />
    <span class="title ml-3 mr-5">{{ config.app && config.app.title }} - {{ $t($route.name + ".pageTitle") }}</span>
    <v-text-field solo-inverted flat hide-details label="Search" prepend-inner-icon="mdi-search" v-if="false" />
    <v-spacer />

    <v-badge v-if="isLoggedIn" :value="hover" color="deep-purple accent-4" :content="uName" left bottom transition="slide-x-transition">
      <v-hover v-model="hover">
        <v-avatar color="lime darken-2" size="32">
          <v-img v-if="false" src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
          <span v-else>{{ initials }}</span>
        </v-avatar>
      </v-hover>
    </v-badge>

    <template v-slot:extension v-if="hasSlotData">
      <slot />
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { getColor } from "@/services/theme";

export default {
  data: () => ({
    drawer: null,
    hover: null,
  }),

  props: {
    headerIconClick: {
      type: Function,
    },
  },

  computed: {
    ...mapGetters(["isLoggedIn", "userName", "isAdmin", "config"]),
    makeProminent: function() {
      return this.$route.meta.prominentHeader || false;
    },
    headerStyle: function() {
      return `background-color: ${getColor("headerbg")}`;
    },
    hasSlotData: function() {
      return this.$slots.default;
    },
    uName: function() {
      let u = this.userName;
      if (this.isAdmin) {
        u += " (admin)";
      }
      return u;
    },
    initials: function() {
      return this.userName ? this.userName.substring(0, 1) : "";
    },
  },

  methods: {
    
  },

  components: {},
};
</script>

<style></style>
