<template>
  <v-app>
    <Header :headerIconClick="headerIconClick" class="header">
      <slot name="header"></slot>
    </Header>
    <Navigation v-if="isLoggedIn" ref="nav" class="d-print-none" />
    <v-main class="top" :style="mainStyle" v-bind:class="{ dark: isDark }">
      <v-container fluid fill-height class="pa-0">
        <v-progress-linear :active="isLoading" indeterminate absolute top color="accent"></v-progress-linear>
        <slot />
      </v-container>
      <Footer v-if="isAdmin || true"></Footer>
    </v-main>
    <SnackBar />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { getColor, isDark } from "@/services/theme";
import Header from "@/components/_layout/header";
import Footer from "@/components/_layout/footer";
import Navigation from "@/components/_layout/navigation";
import SnackBar from "@/components/_system/snackbar.vue";

export default {
  data: () => ({}),

  components: {
    Header,
    Footer,
    Navigation,
    SnackBar,
  },

  created() {},

  beforeDestroy() {},

  computed: {
    ...mapGetters(["isAdmin", "isLoading", "isLoggedIn", "config", "isDark"]),
    isDark: function() {
      return isDark();
    },
    mainStyle: function() {
      return `background-color: ${getColor("bg")}`;
    },
  },

  methods: {
    headerIconClick: function() {
      this.$refs.nav.show();
    },
  },
};
</script>

<style lang="scss">
.header {
  .v-chip {
    border-width: 3px;
  }
  .v-chip--active {
    font-weight: bold;
  }
}

.dark {
  .ck-editor__editable,
  .ck.ck-toolbar,
  .ck-toolbar__items > * {
    color: white !important;
    background-color: transparent !important;
  }

  .theme--dark.v-card,
  .theme--dark.v-data-table {
    background-color: #37474f;
  }
}

@media print {
  .v-content {
    padding-left: 0 !important;
  }
}
</style>
